import * as React from 'react';
import {
    Radio,
    RadioGroup,
    Button,
    FormControlLabel,
    FormControl,
    FormLabel,
    DialogTitle,
    Dialog,
    Checkbox,
} from '@material-ui/core';
import { RadioButtonUnchecked, RadioButtonChecked, Close } from '@material-ui/icons';
import { confirmButton, confirmCancelButton, confirmButtonDisabled } from 'components/Button/styles';
import { usePdfDataContext } from 'config/context/pdfDataContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentLayout from 'pdf/DocumentLayout';
import './PDFDialog.scss';
import { useRootState } from 'store/StateProvider';
import { InputBaseStyled } from 'components/forms/Input/styles';
import { usePostExportToSFDC } from 'hooks/query/usePostExportToSFDC';
import { Loader } from 'components/Loader/Loader';
import { toast } from 'react-toastify';
import { exportCSV, flattenArrayOfObject } from 'utils/exportImportCSVUtils';
import {
    BACKUP_DETAILS,
    CUSTOMER_DETAILS,
    CUSTOMER_DETAILS_KEY_LABEL_MAP,
    EXISTING_SOURCE_DATA_SHEET,
    NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING,
    NEW_SOURCE_DATA_SHEET,
    SUMMARY_DETAILS,
    getCurrentTimeFormatted,
    workloadTypeAliases,
} from 'utils/utils';
import { EXISTING_SOURCE_TABLE_PRIMARY_HEADERS, PRICING_INFO_TITLES, getBackupTableTitles } from 'views/constants';
import { getDocumentData } from 'pdf/utils';
import { corologixUtils } from 'corologix/utils';

const EXPORT_AS_PDF_LABLE = 'PDF';
const EXPORT_AS_CSV_LABLE = 'CSV';

type Props = {
    isOpen: boolean;
    summaryGraphUrl: string;
    sources;
    backupData;
    sku;
    backupACV;
    tilesData;
    isPriceInformationChecked: boolean;
    changePriceInformationChecked: React.Dispatch<React.SetStateAction<boolean>>;
    pdfReady: boolean;
    handleClose: () => void;
    isCustomerInfoVisibleChecked: boolean;
    changeIsCustomerInfoVisibleChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const getNewSourceData = (data) => {
    const flattenData = flattenArrayOfObject(data);
    return flattenData.map((row) => {
        const obj = {};
        Object.keys(row).forEach((key) => {
            if (key === 'ltr') {
                obj['Warm/LTR'] = `${row[key]}` === 'true' ? 'LTR' : 'Warm';
                obj['Warm/LTR'] = row.type === 'archiveFsNas' ? 'N/A' : obj['Warm/LTR'];
            } else if (key === 'type') {
                obj[NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING[key]] = workloadTypeAliases[row[key]];
            } else if (NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING[key]) {
                obj[NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING[key]] = row[key];
            }
        });
        return obj;
    });
};

const getExistingSourceData = (data) =>
    data.map((row) => {
        const obj = {};
        Object.keys(EXISTING_SOURCE_TABLE_PRIMARY_HEADERS).forEach((key) => {
            if (row[key]) {
                obj[EXISTING_SOURCE_TABLE_PRIMARY_HEADERS[key]] = row[key];
            }
        });
        return obj;
    });

const getCustomerData = (customerDetails) => {
    const data = [];
    Object.keys(CUSTOMER_DETAILS_KEY_LABEL_MAP).forEach((key) => {
        if (customerDetails[key]) {
            data.push({
                label: CUSTOMER_DETAILS_KEY_LABEL_MAP[key],
                value: customerDetails[key],
            });
        }
    });
    return data;
};

const getBackupTableData = (backupTableData, labelMap) => {
    const data = [];
    const dataYears = backupTableData.dataYears.data;

    Object.keys(labelMap).forEach((key) => {
        const obj = {
            '': labelMap[key],
        };
        dataYears.forEach((year, index) => {
            obj[year] = backupTableData[key].data[index];
        });
        data.push(obj);
    });

    return data;
};

const PDFDialog = ({
    isOpen,
    summaryGraphUrl,
    sources,
    backupData,
    sku,
    backupACV,
    tilesData,
    handleClose,
    isPriceInformationChecked,
    changePriceInformationChecked,
    pdfReady,
    isCustomerInfoVisibleChecked,
    changeIsCustomerInfoVisibleChecked,
}: Props): JSX.Element => {
    const { timeRange, priceInformation, effectivePrice, isCustomerInfoVisible } = usePdfDataContext();
    const {
        auth: { isDellUser: isDell, isDruvaUser },
        sourcesData: { customerDetails, firstYearMonths },
    } = useRootState();

    const [exportAs, setExportAs] = React.useState(EXPORT_AS_PDF_LABLE);
    const [quoteId, setQuoteId] = React.useState('');
    const [exportToSFDC, setExportToSFDC] = React.useState(false);
    const { mutateAsync, isPending } = usePostExportToSFDC();
    const [timeStamp, setTimeStamp] = React.useState(new Date().valueOf());

    const {
        dateText,
        formattedTiles,
        backupTableData,
        sourcesTableData,
        sourcesColumnWidths,
        existingSourcesTableData,
    } = getDocumentData(
        sources,
        backupData,
        sku,
        backupACV,
        effectivePrice,
        tilesData,
        timeRange,
        priceInformation,
        customerDetails.deploymentSummary,
    );

    const BACKUP_TABLE_TITLES = getBackupTableTitles(isDell, +customerDetails.balanceCredits < 0);

    const PDFDocumentLayout = (
        <DocumentLayout
            summaryGraphSrc={summaryGraphUrl}
            priceInformation={priceInformation}
            isDell={isDell}
            customerDetails={customerDetails}
            showCustomerInfo={isCustomerInfoVisible}
            dateText={dateText}
            formattedTiles={formattedTiles}
            backupTableData={backupTableData}
            sourcesTableData={sourcesTableData}
            sourcesColumnWidths={sourcesColumnWidths}
            existingSourcesTableData={existingSourcesTableData}
        />
    );

    const getExportCSVData = () => {
        const backupTableTitleMap = {
            ...BACKUP_TABLE_TITLES,
            ...(!isDell && isPriceInformationChecked ? PRICING_INFO_TITLES : {}),
        };
        if (customerDetails.customerType === 'New') {
            delete backupTableTitleMap.dataCreditsBalance;
        }
        const sheetsData = [
            ...(isCustomerInfoVisibleChecked
                ? [{ sheetName: CUSTOMER_DETAILS, data: getCustomerData(customerDetails) }]
                : []),
            {
                sheetName: SUMMARY_DETAILS,
                data: formattedTiles.map((tile) => ({ label: tile.label, value: tile.value })),
            },
            {
                sheetName: BACKUP_DETAILS,
                data: getBackupTableData(backupTableData, backupTableTitleMap),
            },
            ...(customerDetails?.deploymentSummary?.details?.length > 0
                ? [
                      {
                          sheetName: EXISTING_SOURCE_DATA_SHEET,
                          data: getExistingSourceData(customerDetails?.deploymentSummary?.details),
                      },
                  ]
                : []),
            ...(sources?.length > 0 ? [{ sheetName: NEW_SOURCE_DATA_SHEET, data: getNewSourceData(sources) }] : []),
        ];
        return sheetsData;
    };

    const getExportPayload = () => {
        const phoenixCloudUrl = customerDetails?.instanceList?.find(
            (obj) => obj.instanceId === customerDetails.selectedProductCustomerId,
        )?.cloudName;
        return {
            ...(phoenixCloudUrl ? { cloudName: phoenixCloudUrl } : {}),
            creditBalance: +customerDetails.balanceCredits,
            quoteID: quoteId,
        };
    };

    const handleExport = () => {
        const currentTimeStamp = new Date().valueOf();
        setTimeStamp(currentTimeStamp);
        let exportInProgress = false;
        if (exportToSFDC) {
            exportInProgress = true;
            mutateAsync(getExportPayload()).then((data: any) => {
                exportInProgress = false;
                if (data.success) {
                    toast.success('Successfully exported to salesforce', {
                        position: 'bottom-right',
                    });
                    handleClose();
                }
            });
        }
        if (exportAs === EXPORT_AS_CSV_LABLE) {
            let fileName = `sizer_results_${getCurrentTimeFormatted(currentTimeStamp, '-')}.csv`;
            if (customerDetails.customername) {
                fileName = `${customerDetails.customername}_${fileName}`;
            }
            exportCSV(getExportCSVData(), fileName)
                .then(() => {
                    toast.success('CSV Exported Successfully', {
                        position: 'bottom-right',
                    });
                    if (!exportInProgress) {
                        handleClose();
                    }
                })
                .catch((error) => {
                    toast.error(`Failed to export CSV ${error}`, {
                        position: 'bottom-right',
                    });
                });
        } else if (!exportInProgress) {
            // Timeout is required when only pdf is downloaded, as popup is closed even before downloading the pdf.
            setTimeout(() => {
                handleClose();
            }, 400);
        }
        corologixUtils.sendCustomMeasurement({
            customerType: customerDetails.customerType,
            creditBalance: customerDetails.creditBalanceAddedUsing,
            exportFormat: exportAs, // or "csv" based on what the user selects
            priceInformation: !isDell && isPriceInformationChecked ? 'Enabled' : 'Disabled',
            sizingId: customerDetails.sizingId,
            firstYearMonthsConsidered: customerDetails.customerType === 'Existing' ? firstYearMonths : null,
            sizingStatus: null,
        });
    };

    /**
     * true, for dell user, export to sfdc is disabled so not checking quoteId is empty or not
     * false, when export to sfdc is true & quoteId is not present for non-dell users
     * */
    const isNonDellUserWithExportToSFDCHasQuoteId = !isDell && exportToSFDC && !quoteId;

    const showExportPdfButton = () => {
        // hide, when pdf is not ready
        if (!pdfReady) {
            return false;
        }
        if (isNonDellUserWithExportToSFDCHasQuoteId) {
            return false;
        }
        // hide, when export as pdf is not checked
        if (exportAs !== EXPORT_AS_PDF_LABLE) {
            return false;
        }
        // Show in all other cases
        return true;
    };

    const showExportCSVButton = () => {
        // hide, when pdf is not ready
        if (!pdfReady) {
            return false;
        }

        if (exportAs !== EXPORT_AS_CSV_LABLE) {
            return false;
        }
        /**
         * For dell user, export to sfdc is disabled so not checking quoteId is empty or not
         * hide, when export to sfdc is true & quoteId is not present for non-dell users
         * */
        if (isNonDellUserWithExportToSFDCHasQuoteId) {
            return false;
        }
        // Show in all other cases
        return true;
    };

    const showDisabledExportButton = () => {
        // show, when pdf is not ready
        if (!pdfReady) {
            return true;
        }

        /**
         * For dell user, export to sfdc is disabled so not checking quoteId is empty or not
         * show, when export to sfdc is true & quoteId is not present for non-dell users
         * */
        if (isNonDellUserWithExportToSFDCHasQuoteId) {
            return true;
        }
        // hide in all other cases
        return false;
    };

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            classes={{
                container: 'dialog',
            }}
        >
            <Loader isLoading={isPending}>
                <div className="export-dialog">
                    <DialogTitle id="dialog-title">Export Sizing Details</DialogTitle>
                    <Close className="dialog-close-icon" color="disabled" onClick={handleClose} />

                    <div className="export-dialog-content">
                        <div className="details-item">
                            <FormControl>
                                <FormLabel className="form-label-container">Export as</FormLabel>
                                <RadioGroup
                                    value={exportAs}
                                    onChange={(e) => {
                                        setExportAs(e.target.value);
                                    }}
                                    row
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                                checkedIcon={<RadioButtonChecked fontSize="large" />}
                                            />
                                        }
                                        label={EXPORT_AS_PDF_LABLE}
                                        value={EXPORT_AS_PDF_LABLE}
                                        className="qa-radio-export-pdf"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                                checkedIcon={<RadioButtonChecked fontSize="large" />}
                                            />
                                        }
                                        label={EXPORT_AS_CSV_LABLE}
                                        value={EXPORT_AS_CSV_LABLE}
                                        className="qa-radio-export-csv"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="details-item">
                            <FormControl>
                                <FormLabel className="form-label-container">Show Customer Details</FormLabel>
                                <Checkbox
                                    style={{ padding: '0px', marginRight: '10px' }}
                                    onChange={(e) => changeIsCustomerInfoVisibleChecked(e.target.checked)}
                                    className="checkbox qa-checkbox-export-show-customer-details"
                                    checked={isCustomerInfoVisibleChecked}
                                />
                            </FormControl>
                        </div>
                        {!isDell && (
                            <>
                                <div className="details-item">
                                    <FormControl>
                                        <FormLabel className="form-label-container">Show Price information</FormLabel>
                                        <Checkbox
                                            style={{ padding: '0px', marginRight: '10px' }}
                                            onChange={(e) => changePriceInformationChecked(e.target.checked)}
                                            className="checkbox qa-checkbox-export-show-price-info"
                                            checked={isPriceInformationChecked}
                                        />
                                    </FormControl>
                                </div>
                                {isDruvaUser && (
                                    <>
                                        <div className="details-item">
                                            <FormControl>
                                                <FormLabel className="form-label-container">
                                                    Export to Salesforce
                                                </FormLabel>
                                                <Checkbox
                                                    style={{ padding: '0px', marginRight: '10px' }}
                                                    onChange={(e) => setExportToSFDC(e.target.checked)}
                                                    className="checkbox qa-checkbox-export-salesforce"
                                                    checked={exportToSFDC}
                                                />
                                            </FormControl>
                                        </div>

                                        {exportToSFDC && (
                                            <div className="details-item">
                                                <FormControl>
                                                    <FormLabel className="form-label-container">
                                                        Salesforce Quote ID / Opportunity ID
                                                    </FormLabel>
                                                    <InputBaseStyled
                                                        name="salesforceQuoteId"
                                                        style={{ width: '250px', marginTop: '0' }}
                                                        value={quoteId}
                                                        onChange={(e) => setQuoteId(e.target.value)}
                                                        type="text"
                                                        disableUnderline
                                                        placeholder="Enter Quote ID / Opportunity ID"
                                                        className="qa-input-export-quote-id"
                                                    />
                                                </FormControl>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="export-dialog-buttons">
                        <Button style={confirmCancelButton} onClick={handleClose}>
                            Cancel
                        </Button>
                        {showExportPdfButton() && (
                            <PDFDownloadLink
                                document={PDFDocumentLayout}
                                fileName={`${
                                    // eslint-disable-next-line prefer-template
                                    customerDetails.customername ? customerDetails.customername + '_' : ''
                                }sizer_results_${getCurrentTimeFormatted(timeStamp, '-')}.pdf`}
                            >
                                <Button style={confirmButton} onClick={handleExport} className="qa-btn-modal-export">
                                    Export
                                </Button>
                            </PDFDownloadLink>
                        )}
                        {showExportCSVButton() && (
                            <Button onClick={handleExport} style={confirmButton} className="qa-btn-modal-export">
                                Export
                            </Button>
                        )}
                        {showDisabledExportButton() && <Button style={confirmButtonDisabled}>Export</Button>}
                    </div>
                </div>
            </Loader>
        </Dialog>
    );
};

export default PDFDialog;
