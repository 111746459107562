import { WorkloadTypesEnum } from '../../../types';
import { AssumptionsState, AssumptionsValues } from './assumptions.types';

export const assumptionsInitialState: AssumptionsState = {
    defaultAssumptions: {} as AssumptionsValues,
    isOpen: false,
    isEditing: false,
    isAssumptionsDialogOpen: false,
    dedupe: {
        nas: 2.5,
        dtc: 1,
        windows: 2.5,
        linux: 2.5,
        msSql: 1.3,
        oracle: 1.3,
        vMware: 3,
        hyperV: 3,
        nutanixAhv: 3,
        archiveFsNas: 1,
        ec2: 3,
        sapHana: 1.3,
        azureVm: 3,
        rds: 1,
        azureSql: 1.3,
        data: [
            {
                dataTypesAndDedupeRatio: 'Cloud Workloads',
                workloadType: WorkloadTypesEnum.EC2,
                dedupe: 3.0,
                key: 'ec2',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.RDS,
                dedupe: 1.0,
                key: 'rds',
            },
            {
                dataTypesAndDedupeRatio: 'VM',
                workloadType: WorkloadTypesEnum.AZUREVM,
                dedupe: 3.0,
                key: 'azureVm',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.AZURESQL,
                dedupe: 1.3,
                key: 'azureSql',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.VMWARE,
                dedupe: 3.0,
                key: 'vMware',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.HYPERV,
                dedupe: 3.0,
                key: 'hyperV',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.NUTANIXAHV,
                dedupe: 3.0,
                key: 'nutanixAhv',
            },
            {
                dataTypesAndDedupeRatio: 'Files',
                workloadType: WorkloadTypesEnum.NAS,
                dedupe: 2.5,
                key: 'nas',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.WINDOWS,
                dedupe: 2.5,
                key: 'windows',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.LINUX,
                dedupe: 2.5,
                key: 'linux',
            },
            {
                dataTypesAndDedupeRatio: 'Archive',
                workloadType: WorkloadTypesEnum.ARCHIVEFSNAS,
                dedupe: 1.0,
                key: 'archiveFsNas',
            },
            {
                dataTypesAndDedupeRatio: 'Database',
                workloadType: WorkloadTypesEnum.MSSQL,
                dedupe: 1.3,
                key: 'msSql',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.ORACLE,
                dedupe: 1.3,
                key: 'oracle',
            },
            {
                dataTypesAndDedupeRatio: '',
                workloadType: WorkloadTypesEnum.SAPHANA,
                dedupe: 1.3,
                key: 'sapHana',
            },
        ],
    },
    ltr: {
        ltr: 0.8,
        data: [
            {
                ltrCreditRate: 'LTR credit rate',
                empty: '',
                credit: 0.8,
                key: 'ltr',
            },
        ],
    },
    dtc: {
        dtc: 0.5,
        data: [
            {
                dtcCreditRate: 'Archive credit rate',
                empty: '',
                credit: 0.5,
                key: 'dtc',
            },
        ],
    },
    pricing: {
        business: 0,
        enterprise: 0,
        elite: 0,
        govCloudAddon: 0,
        data: [
            {
                pricing: 'Business',
                cost: '',
                perMonth: '/TB/m',
                key: 'business',
            },
            {
                pricing: 'Enterprise',
                cost: '',
                perMonth: '/TB/m',
                key: 'enterprise',
            },
            {
                pricing: 'Elite',
                cost: '',
                perMonth: '/TB/m',
                key: 'elite',
            },
            {
                pricing: 'GovCloud Addon',
                cost: '',
                perMonth: '/TB/m',
                key: 'govCloudAddon',
            },
        ],
    },
    changeRates: {
        weeklyMultiplier: 3,
        weeklyCap: 0.07,
        monthlyMultiplier: 8,
        monthlyCap: 0.2,
        yearlyMultiplier: 35,
        yearlyCap: 0.5,
        data: [
            {
                changesRates: 'Weekly change rate',
                multiplier: 3,
                cap: 0.07,
                keyMultiplier: 'weeklyMultiplier',
                keyCap: 'weeklyCap',
            },
            {
                changesRates: 'Monthly change rate',
                multiplier: 8,
                cap: 0.2,
                keyMultiplier: 'monthlyMultiplier',
                keyCap: 'monthlyCap',
            },
            {
                changesRates: 'Yearly change rate',
                multiplier: 35,
                cap: 0.5,
                keyMultiplier: 'yearlyMultiplier',
                keyCap: 'yearlyCap',
            },
        ],
    },
};
