import React, { useCallback, memo, useMemo } from 'react';
import _ from 'lodash';
import { FieldArrayRenderProps, useField, FormikTouched } from 'formik';
import Input from 'components/forms/Input/Input';
import Select from 'components/forms/Select/Select';
import RowError from 'assets/error.png';
import RowCorrect from 'assets/correct.png';
import { subtractCount } from 'store/slices/sources/sourcesActions';
import { WorkloadEnum, WorkloadTypesEnum } from 'types';
import { InputBaseStyled } from 'components/forms/Input/styles';
import { getAnnualGrowth, getDailyGrowth, getDeploymentPeriodBasedOnDataSize } from 'utils/utils';
import useAssumptions from 'views/Sources/hooks/useAssumptions';
import useDefaults from 'views/Sources/hooks/useDefaults';
import { useRootState } from 'store/StateProvider';
import TextField from 'components/forms/TextField/TextField';
import useSources from '../../hooks/useSources';
import { sourceValidation, sourcesValidationSchema } from '../SourcesForm.validation';
import './SourceFields.scss';
import { SourceType } from '../../../../store/slices/sources/sources.types';

const LTRDisabledWorkloadType = [
    WorkloadEnum.SAPHANA,
    WorkloadEnum.ARCHIVEFSNAS,
    WorkloadEnum.RDS,
    'select',
    WorkloadEnum.AZURESQL,
];
const retentionMappingMoreThanOneYear = {
    daily: 365,
    weekly: 52,
    monthly: 12,
    yearly: 1,
};

const MINIMUM_RETENTION_INPUT_NUMBER = 0;
const MAXIMUM_RETENTION_INPUT_NUMBER = 99999;
const MAXIMUM_DAILY_RETENTION_INPUT_NUMBER_SAPHANA = 365;

interface Props {
    index: number;
    fieldsArray: FieldArrayRenderProps;
    touched: FormikTouched<{ sources: Array<SourceType> }>;
    showDailyChangeConfirmation: Function;
}
const SourceFields = memo(({ index, fieldsArray, touched, showDailyChangeConfirmation }: Props) => {
    const assumptions = useAssumptions();
    const fieldName = `sources.${index}`;
    const [field, meta] = useField(fieldName);
    const {
        dispatch,
        state: {
            sourcesData: { workloadsOptions },
        },
    } = useSources();
    const {
        auth: { isDellUser: isDell },
    } = useRootState();
    const defaultValues = useDefaults();
    const workloadDefaultValue: SourceType = defaultValues[field.value.type];
    const {
        annualGrowth,
        dailyGrowth,
        dailyChange,
        retention: { daily, weekly, monthly, yearly },
    } = workloadDefaultValue;

    const removeSource = useCallback(() => {
        fieldsArray.remove(index);
        dispatch(subtractCount());
    }, [dispatch, fieldsArray, index]);

    const isAnyFieldTouchedAndInvalid = _.intersection(
        (touched?.sources && touched.sources[index] && Object.keys(touched.sources[index])) || [],
        (meta.error && Object.keys(meta.error)) || [],
    );
    const isRowHasSubmitErrors =
        meta.error && Boolean(Object.values(meta.error).filter(Boolean).length) && !!isAnyFieldTouchedAndInvalid.length;
    const isSourceValid = sourceValidation.isValidSync(meta.value);
    const isSourcesValid = sourcesValidationSchema.isValidSync(meta.value);

    // TODO (P.C): toLowerCase() - temporary solution, need to standarize workload strings in both frontend and backend.
    const ltrOptions = ['Warm', 'LTR'];

    const isRetentionOverAYear = () => {
        if (field.value.retention.weekly >= 52) return true;
        if (field.value.retention.monthly >= 12) return true;
        if (field.value.retention.yearly > 0) return true;
        fieldsArray.form.values.sources[index].ltr = 'warm';

        return false;
    };

    const handleWorkloadChange = (e) => {
        const workload = e.target.value as WorkloadEnum;
        const selectedWorkloadDefaultValue = defaultValues[workload];
        let ltr;
        if (LTRDisabledWorkloadType.includes(workload)) {
            ltr = workload === WorkloadEnum.ARCHIVEFSNAS ? 'nA' : 'warm';
        } else {
            ltr = selectedWorkloadDefaultValue.ltr ? 'ltr' : 'warm';
        }
        fieldsArray.replace(index, {
            ...field.value,
            retention: selectedWorkloadDefaultValue.retention,
            ltr,
            dtc: selectedWorkloadDefaultValue.dtc ? 'yes' : 'no',
            dailyChange: selectedWorkloadDefaultValue.dailyChange,
            annualGrowth: selectedWorkloadDefaultValue.annualGrowth,
            dailyGrowth: selectedWorkloadDefaultValue.dailyGrowth,
            dedupe: assumptions.dedupe[workload] || 0,
        });
    };

    const updateDeploymentPeriod = (e) => {
        meta.value.deploymentPeriod = getDeploymentPeriodBasedOnDataSize(e.target.value);
    };

    const updateDedupeRatio = (e) => {
        meta.value.dedupe = +e.target.value;
    };

    const checkToEnableLTR = (value, type) => {
        if (type !== 'daily' && +value >= retentionMappingMoreThanOneYear[type]) {
            meta.value.ltr = 'ltr';
        }
    };

    const handleDailyChange = (e) => {
        if (meta.value.type === WorkloadEnum.ARCHIVEFSNAS && e.target.value > 0) {
            showDailyChangeConfirmation();
        }
    };

    const handleRetentionChange = (e, type) => {
        if (!LTRDisabledWorkloadType.includes(meta.value.type)) {
            checkToEnableLTR(e.target.value, type);
        }
    };

    const handleGrowthChange = (e, type) => {
        if (e.target.value !== '') {
            if (type === 'annualGrowth') {
                meta.value.dailyGrowth = getDailyGrowth(e.target.value);
            } else {
                meta.value.annualGrowth = getAnnualGrowth(e.target.value);
            }
        }
    };

    const filteredWorkloadsOptions = useMemo(() => {
        if (!isDell) return workloadsOptions;
        return workloadsOptions.filter((option) => option !== WorkloadTypesEnum.RDS);
    }, [isDell, workloadsOptions]);

    return (
        <>
            <div className="counter">
                <div className="source__couter">
                    {isRowHasSubmitErrors && (!isSourceValid || !isSourcesValid) && (
                        <img src={RowError} alt="Source Invalid" />
                    )}
                    {isSourceValid && isSourcesValid && <img src={RowCorrect} alt="Source Valid" />}
                    <h3>{`${index + 1}. `}</h3>
                    <button className="button-remove" type="button" onClick={removeSource}>
                        <span className="cross" />
                    </button>
                </div>
            </div>
            <div className="grid col3">
                <Select
                    clearError
                    name={`${fieldName}.type`}
                    options={filteredWorkloadsOptions}
                    handleChange={handleWorkloadChange}
                />
                {meta.value.type === WorkloadEnum.RDS ? (
                    <InputBaseStyled
                        name={`${fieldName}.dedupe`}
                        type="number"
                        disableUnderline
                        disabled
                        defaultValue={assumptions.dedupe.rds}
                    />
                ) : (
                    <Input name={`${fieldName}.dedupe`} type="number" onValueChange={updateDedupeRatio} isEditable />
                )}
                <Input
                    clearError
                    name={`${fieldName}.size`}
                    type="number"
                    onValueChange={updateDeploymentPeriod}
                    isEditable
                />
            </div>
            <div className="grid data-change-col">
                <Input
                    name={`${fieldName}.annualGrowth`}
                    type="number"
                    isEditable
                    defaultValue={annualGrowth}
                    onValueChange={(e) => handleGrowthChange(e, 'annualGrowth')}
                />
                <Input
                    name={`${fieldName}.dailyGrowth`}
                    type="number"
                    isEditable
                    defaultValue={dailyGrowth}
                    onValueChange={(e) => handleGrowthChange(e, 'dailyGrowth')}
                />
                <Input
                    name={`${fieldName}.dailyChange`}
                    type="number"
                    isEditable
                    defaultValue={dailyChange}
                    onInputBlur={handleDailyChange}
                />
                <Input name={`${fieldName}.deploymentPeriod`} type="number" isEditable />
            </div>

            <div className="grid col1">
                <Select
                    name={`${fieldName}.ltr`}
                    options={meta.value.type === WorkloadEnum.ARCHIVEFSNAS ? ['N/A'] : ltrOptions}
                    disabled={LTRDisabledWorkloadType.includes(meta.value.type) || !isRetentionOverAYear()}
                />
            </div>
            <div className="grid col4">
                <Input
                    clearError
                    name={`${fieldName}.retention.daily`}
                    type="number"
                    isEditable
                    defaultValue={daily}
                    min={MINIMUM_RETENTION_INPUT_NUMBER}
                    max={
                        meta.value.type === WorkloadEnum.SAPHANA
                            ? MAXIMUM_DAILY_RETENTION_INPUT_NUMBER_SAPHANA
                            : MAXIMUM_RETENTION_INPUT_NUMBER
                    }
                    disabled={meta.value.type === 'select'}
                    onValueChange={(e) => handleRetentionChange(e, 'daily')}
                />
                {meta.value.type === WorkloadEnum.SAPHANA ? (
                    <InputBaseStyled type="text" disableUnderline disabled defaultValue="N/A" />
                ) : (
                    <Input
                        name={`${fieldName}.retention.weekly`}
                        type="number"
                        isEditable
                        defaultValue={weekly}
                        min={MINIMUM_RETENTION_INPUT_NUMBER}
                        max={MAXIMUM_RETENTION_INPUT_NUMBER}
                        disabled={meta.value.type === 'select'}
                        onValueChange={(e) => handleRetentionChange(e, 'weekly')}
                    />
                )}
                {meta.value.type === WorkloadEnum.SAPHANA ? (
                    <InputBaseStyled type="text" disableUnderline disabled defaultValue="N/A" />
                ) : (
                    <Input
                        name={`${fieldName}.retention.monthly`}
                        type="number"
                        isEditable
                        defaultValue={monthly}
                        min={MINIMUM_RETENTION_INPUT_NUMBER}
                        max={MAXIMUM_RETENTION_INPUT_NUMBER}
                        disabled={meta.value.type === 'select'}
                        onValueChange={(e) => handleRetentionChange(e, 'monthly')}
                    />
                )}
                {meta.value.type === WorkloadEnum.SAPHANA ? (
                    <InputBaseStyled type="text" disableUnderline disabled defaultValue="N/A" />
                ) : (
                    <Input
                        name={`${fieldName}.retention.yearly`}
                        type="number"
                        isEditable
                        defaultValue={yearly}
                        min={MINIMUM_RETENTION_INPUT_NUMBER}
                        max={MAXIMUM_RETENTION_INPUT_NUMBER}
                        disabled={meta.value.type === 'select'}
                        onValueChange={(e) => handleRetentionChange(e, 'yearly')}
                    />
                )}
            </div>
            <div className="grid col1">
                <TextField
                    name={`${fieldName}.additionalDetails.comments`}
                    type="text"
                    multiline
                    rows={2}
                    disabled={meta.value.type === 'select'}
                    isEditable
                    maxLength={260}
                />
            </div>
        </>
    );
});

export default SourceFields;
