import { useRootState } from 'store/StateProvider';
import {
    archiveFsNasSourceDefaultValues,
    azureSqlSourceDefaultValues,
    ec2SourceDefaultValues,
    rdsSourceDefaultValues,
    saphanaSourceDefaultValues,
    sourceDefaultValues,
} from 'store/slices';
import { DefaultValueType } from 'store/slices/sources/sources.types';
import { WorkloadEnum } from 'types';
import { getDailyGrowth } from 'utils/utils';
import { workloadTypeMappingForAPI } from 'views/constants';

const useDefaults = () => {
    const {
        sourcesData: {
            customerDetails: { deploymentSummary },
        },
    } = useRootState();

    const defaultValues: DefaultValueType = {
        [WorkloadEnum.SELECT]: sourceDefaultValues,
        [WorkloadEnum.EC2]: ec2SourceDefaultValues,
        [WorkloadEnum.RDS]: rdsSourceDefaultValues,
        [WorkloadEnum.AZUREVM]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.AZURESQL]: azureSqlSourceDefaultValues,
        [WorkloadEnum.VMWARE]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.HYPERV]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.NUTANIXAHV]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.NAS]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.WINDOWS]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.LINUX]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.ARCHIVEFSNAS]: archiveFsNasSourceDefaultValues,
        [WorkloadEnum.MSSQL]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.ORACLE]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.SAPHANA]: saphanaSourceDefaultValues,
    };

    const serviceToAnnualGrowthRateMapping = {};
    const serviceToDailyChangeRateMapping = {};
    if (Array.isArray(deploymentSummary?.details)) {
        for (const item of deploymentSummary?.details) {
            const { serviceName, annualGrowthRate, dailyChangeRate } = item;
            serviceToAnnualGrowthRateMapping[serviceName] = annualGrowthRate;
            serviceToDailyChangeRateMapping[serviceName] = dailyChangeRate;
        }
    }

    for (const workload of Object.keys(defaultValues)) {
        const serviceName = workloadTypeMappingForAPI[workload];
        if (serviceToAnnualGrowthRateMapping[serviceName] !== undefined) {
            defaultValues[workload].annualGrowth = serviceToAnnualGrowthRateMapping[serviceName];
            defaultValues[workload].dailyGrowth = getDailyGrowth(serviceToAnnualGrowthRateMapping[serviceName]);
        }
        if (serviceToDailyChangeRateMapping[serviceName] !== undefined) {
            defaultValues[workload].dailyChange = serviceToDailyChangeRateMapping[serviceName];
        }
    }

    return defaultValues;
};

export default useDefaults;
