import { CLOUD_OPTIONS, SKU_OPTIONS } from 'config/setup/constants';
import { SkuState } from './sku.types';

export const skuInitialState: SkuState = {
    plan: '',
    cloud: '',
    discounts: [0],
    defaultValues: {
        plan: 'select',
        cloud: 'select',
        discounts: [0],
    },
    initialOptions: {
        sku: SKU_OPTIONS,
        cloud: CLOUD_OPTIONS,
    },
};
