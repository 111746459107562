import * as Yup from 'yup';

const ARCHIVE_MINIMUM_DAILY_RETENTION_INPUT_NUMBER = 365;
export const sourceValidation = Yup.object().shape({
    type: Yup.string().notOneOf(['select'], 'Choose one of values').required('Required'),
    size: Yup.number().moreThan(0, 'Data size must be greater than 0').required('Required'),
    annualGrowth: Yup.number().required('Required'),
    dailyGrowth: Yup.number().required('Required'),
    dailyChange: Yup.number().required('Required'),
    deploymentPeriod: Yup.number(),
    retention: Yup.object()
        .shape({
            daily: Yup.number().required('Required'),
            weekly: Yup.number().required('Required'),
            monthly: Yup.number().required('Required'),
            yearly: Yup.number().required('Required'),
        })
        .test(
            'a1WhenBIsZ',
            'Number of daily recovery points should not exceed the max LTR threshold of 60',
            function (value) {
                if (this.parent.ltr === 'ltr' && value.daily > 60) {
                    return false;
                }

                return true;
            },
        ),
    additionalDetails: Yup.object().shape({
        comments: Yup.string(),
    }),
    ltr: Yup.string().oneOf(['nA', 'warm', 'ltr']),
    dtc: Yup.string().required('Required'),
});
export const sourcesValidationSchema = Yup.object().shape({
    sources: Yup.array()
        .of(sourceValidation)
        .required('Must have a source')
        .test('minDaily', 'The minimum value is 365', function (sources) {
            const errors = [];
            for (let index = 0; index < sources.length; index += 1) {
                if (
                    sources[index].type === 'archiveFsNas' &&
                    sources[index].retention.daily < ARCHIVE_MINIMUM_DAILY_RETENTION_INPUT_NUMBER
                ) {
                    errors.push(
                        this.createError({
                            message: `minimum value is 365 for daily at index ${index}`,
                            path: `sources.${index}.retention.daily`,
                        }),
                    );
                }
            }
            if (errors.length > 0) {
                throw new Yup.ValidationError(errors);
            }

            return true;
        }),
});
