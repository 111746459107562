import { UseQueryResult } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { camelCase } from 'lodash';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { setDeploymentConfig } from 'store/slices/deploymentConfig/deploymentConfigActions';
import { DirectorySvcResponseState } from 'types';
import { updateSku } from 'store/slices/actions';
import { SkuOptionsEnum } from 'store/slices/sku/sku.types';
import { getCloudType } from 'utils/utils';
import { cloudTypeMappingForAPI } from 'views/constants';
import { useAPIQuery } from '../useAPIQuery';

const editionMapping = {
    Enterprise: camelCase(SkuOptionsEnum.ENTERPRISE),
    Business: camelCase(SkuOptionsEnum.BUSINESS),
    Elite: camelCase(SkuOptionsEnum.ELITE),
};

export function useGetDeployementDetails(
    selectedProductCustomerId,
    enabled,
): UseQueryResult<{ result: DirectorySvcResponseState; statusCode: number; success: boolean }, Error> {
    const dispatch = useRootDispatch();
    const {
        deploymentConfig: { globalApiUrlCloudNameMap },
        sourcesData: {
            customerDetails: { instanceList, customerSfdcOemId },
        },
    } = useRootState();

    const url = `/directorysvc/v1/services`;
    const method = 'GET';

    const handleSelect = (data: { result: DirectorySvcResponseState[]; statusCode: number; success: boolean }) => {
        if (data.result?.length > 0) {
            const [parsedData] = data.result;
            return { ...data, result: parsedData };
        }
        return data;
    };
    const payload = {
        services: 'APIEndpoint',
        consumerTypeID: 12289,
        consumerID: selectedProductCustomerId,
    };

    const selectedInstance = instanceList.find((obj) => obj.instanceId === selectedProductCustomerId);
    const phoenixCloudUrl = selectedInstance?.cloudName;
    const cloudTypeEnum = getCloudType(phoenixCloudUrl);
    const cloudType = useMemo(() => cloudTypeMappingForAPI[cloudTypeEnum], [cloudTypeEnum]);

    const query: UseQueryResult<{ result: DirectorySvcResponseState; statusCode: number; success: boolean }, Error> =
        useAPIQuery({
            key: ['deploymentSummary', customerSfdcOemId, selectedProductCustomerId],
            method,
            payload,
            url,
            baseUrl: globalApiUrlCloudNameMap[cloudType],
            select: handleSelect,
            enabled: enabled && !!phoenixCloudUrl,
            options: {
                staleTime: 24 * 60 * 60 * 1000, // 24 hour stale time to avoid refetching the same API
            },
        });

    useEffect(() => {
        if (query?.data?.success && !query.isError && enabled && selectedInstance) {
            dispatch(setDeploymentConfig(query.data?.result as DirectorySvcResponseState));
            dispatch(
                updateSku({
                    plan: editionMapping[selectedInstance.edition],
                    cloud: camelCase(cloudTypeEnum),
                    discounts: [0],
                }),
            );
        }
    }, [query.data, query.isError, enabled, selectedInstance]);
    return query;
}
