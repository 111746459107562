import _ from 'lodash';
import moment from 'moment-timezone';
import { AssumptionsValues } from 'store/slices/assumptions/assumptions.types';
import { SourceType, DeploymentSummary } from 'store/slices/sources/sources.types';
import { SkuDefaultValuesType } from 'store/slices/sku/sku.types';
import { EXISTING_SOURCE_TABLE_PRIMARY_HEADERS } from 'views/constants';
import { WorkloadTypesEnum, BackupData, TilesType, DocumentDataType } from '../types';

const padStart = (value: string | number, size: number) => {
    let output = value.toString();
    while (output.length < size) output = `0${output}`;
    return output;
};

export const sourcesColumnWidths = [
    '3%',
    '10%',
    '7%',
    '8%',
    '8%',
    '8%',
    '7%',
    '9%',
    '9%',
    '4%',
    '3%',
    '3%',
    '3%',
    '20%',
];

const sourcesWidth = parseInt(sourcesColumnWidths[0], 10);
const serverDetailsWidth =
    parseInt(sourcesColumnWidths[1], 10) + parseInt(sourcesColumnWidths[2], 10) + parseInt(sourcesColumnWidths[3], 10);
const dataChangeWidth =
    parseInt(sourcesColumnWidths[4], 10) +
    parseInt(sourcesColumnWidths[5], 10) +
    parseInt(sourcesColumnWidths[6], 10) +
    parseInt(sourcesColumnWidths[7], 10);
const retentionWidth =
    sourcesColumnWidths
        .slice(9)
        .map((value) => parseInt(value, 10))
        .reduce((acc, curr) => acc + curr) - parseInt(sourcesColumnWidths[13], 10);
const additionalDetailsWidth = sourcesColumnWidths[13];

export const sourcesHeaders = {
    primary: [
        {
            header: '#',
            style: [
                {
                    width: `${sourcesWidth}%`,
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
        },
        {
            header: 'Server details',
            style: [
                {
                    width: `${serverDetailsWidth}%`,
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
        },
        {
            header: 'Data change',
            style: [
                {
                    width: `${dataChangeWidth}%`,
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
        },
        {
            header: 'Storage Tier',
            style: {
                width: `${sourcesColumnWidths[8]}%`,
                borderRightColor: 'white',
                borderRightWidth: 1,
            },
        },
        { header: 'Retention', style: { width: `${retentionWidth}%`, borderRightColor: 'white', borderRightWidth: 1 } },
        { header: 'Additional Details', style: { width: `${additionalDetailsWidth}%` } },
    ],
    secondary: [
        { header: '', style: { width: sourcesColumnWidths[0] } },
        {
            header: 'Workload \ntype',
            style: [
                {
                    borderLeftColor: 'white',
                    borderLeftWidth: 1,
                    width: sourcesColumnWidths[1],
                },
            ],
        },
        { header: `Dedupe \nRatio`, style: { width: sourcesColumnWidths[2] } },
        {
            header: `Data size \n(TB)`,
            style: [
                {
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                    width: sourcesColumnWidths[3],
                },
            ],
        },
        { header: `Annual growth \n(%)`, style: { width: sourcesColumnWidths[4] } },
        { header: `Daily growth \n(%)`, style: { width: sourcesColumnWidths[5] } },
        {
            header: `Daily change \n(%)`,
            style: { width: sourcesColumnWidths[6] },
        },
        {
            header: `Deployment Period \n(# Days)`,
            style: [
                {
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                    width: sourcesColumnWidths[7],
                },
            ],
        },
        {
            header: 'Warm/LTR',
            style: {
                borderRightColor: 'white',
                borderRightWidth: 1,
                width: sourcesColumnWidths[8],
            },
        },
        { header: 'D', style: { width: sourcesColumnWidths[9] } },
        { header: 'W', style: { width: sourcesColumnWidths[10] } },
        { header: 'M', style: { width: sourcesColumnWidths[11] } },
        { header: 'Y', style: { width: sourcesColumnWidths[12], borderRightColor: 'white', borderRightWidth: 1 } },
        { header: 'Comments', style: { width: sourcesColumnWidths[13] } },
    ],
};

export const customStyleForBodyCol = {
    comments: { textAlign: 'left', paddingLeft: '3px', paddingRight: '3px' },
};

export const existingSourcesColumnWidths = ['18%', '16%', '16%', '17%', '17%', '16%'];
export const existingSourcesHeaders = {
    primary: [
        {
            header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.serviceName,
            style: [
                {
                    width: existingSourcesColumnWidths[0],
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
            accessor: 'serviceName',
        },
        {
            header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.configuredBackupsets,
            style: [
                {
                    width: existingSourcesColumnWidths[1],
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
            accessor: 'configuredBackupsets',
        },
        {
            header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.dataSize,
            style: [
                {
                    width: existingSourcesColumnWidths[2],
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
            accessor: 'dataSize',
        },
        {
            header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.annualGrowthRate,
            style: [
                {
                    width: existingSourcesColumnWidths[3],
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
            accessor: 'annualGrowthRate',
        },
        {
            header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.dailyChangeRate,
            style: [
                {
                    width: existingSourcesColumnWidths[4],
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
            accessor: 'dailyChangeRate',
        },
        {
            header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.effectiveDedupRatio,
            style: [
                {
                    width: existingSourcesColumnWidths[5],
                    borderRightColor: 'white',
                    borderRightWidth: 1,
                },
            ],
            accessor: 'effectiveDedupRatio',
        },
    ],
};

export const formatDate = (date: Date): string =>
    `${padStart(date.getMonth() + 1, 2)}/${padStart(date.getDate(), 2)}/${date.getFullYear()} ${padStart(
        date.getHours(),
        2,
    )}:${padStart(date.getMinutes(), 2)}:${padStart(date.getSeconds(), 2)}`;

export const getDocumentData = (
    sources: Array<SourceType>,
    backupData: BackupData,
    sku: SkuDefaultValuesType,
    backupACV: number[],
    effectivePrice: number[],
    tilesData: TilesType[],
    timeRange: number,
    priceInformation: boolean,
    deploymentSummary: DeploymentSummary,
): DocumentDataType => {
    const timezoneAbbr = moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset());
    const dateText = `Credit Sizing Tool V2, ${formatDate(new Date())} ${timezoneAbbr}`;

    const backupTableColumnWidth = `${58 / timeRange}%`;
    const displayedTiles = priceInformation ? 6 : 4;

    const keyMap = {
        sourceChanges: 'dataSourceChanges',
        targetData: 'dataTargetData',
        creditsLtr: 'dataCreditsLtr',
        creditsNoLtr: 'dataCreditsStandard',
        creditsBalance: 'dataCreditsBalance',
        creditsTotal: 'dataCreditsTotal',
        dtc: 'dataCreditsDtc',
    };
    type BackupDataBatch = { data: any[]; style: object };
    type MappedBackupData = {
        dataSourceChanges: BackupDataBatch;
        dataTargetData: BackupDataBatch;
        dataCreditsLtr: BackupDataBatch;
        dataCreditsStandard: BackupDataBatch;
        dataCreditsBalance?: BackupDataBatch;
        dataCreditsTotal: BackupDataBatch;
        dataCreditsDtc: BackupDataBatch;
    };

    const mapBackupData = (): MappedBackupData => {
        const mappedData = {};
        Object.keys(backupData).forEach((key) => {
            const newKey = keyMap[key];
            if (newKey === undefined) return;
            mappedData[newKey] = {
                data: backupData[key].slice(0, timeRange),
                style: { width: backupTableColumnWidth },
            };
        });

        return mappedData as MappedBackupData;
    };

    const {
        dataSourceChanges = { data: [], style: {} },
        dataTargetData = { data: [], style: {} },
        dataCreditsLtr = { data: [], style: {} },
        dataCreditsStandard = { data: [], style: {} },
        dataCreditsBalance = { data: [], style: {} },
        dataCreditsTotal = { data: [], style: {} },
        dataCreditsDtc = { data: [], style: {} },
    } = mapBackupData();

    const formattedTiles = tilesData.slice(0, displayedTiles);

    const backupTableData = {
        dataYears: {
            data: _.range(timeRange).map((year) => `Year ${year + 1}`),
            style: [
                {
                    backgroundColor: '#8E939B',
                    color: 'white',
                    fontWeight: 'bold',
                    width: backupTableColumnWidth,
                },
            ],
        },
        dataSourceChanges,
        dataTargetData,
        dataCreditsLtr,
        dataCreditsStandard,
        dataCreditsBalance,
        dataCreditsTotal,
        dataCreditsDtc,
        dataDiscount: priceInformation
            ? {
                  data: sku.discounts.slice(0, timeRange),
                  style: { width: backupTableColumnWidth },
              }
            : undefined,
        dataBackupACV: priceInformation
            ? {
                  data: backupACV.slice(0, timeRange),
                  style: { width: backupTableColumnWidth },
              }
            : undefined,
        dataEffectivePrice: priceInformation
            ? {
                  data: effectivePrice.slice(0, timeRange),
                  style: { width: backupTableColumnWidth },
              }
            : undefined,
    };

    const sourcesTableData = {
        dataPrimaryHeaders: sourcesHeaders.primary,
        dataSecondaryHeaders: sourcesHeaders.secondary,
        dataSources: sources.map((source, index) => {
            let ltr = source.ltr ? 'LTR' : 'Warm';
            if (source.type === 'archiveFsNas') {
                ltr = 'N/A';
            }
            return {
                id: source.id,
                index: `${index + 1}.`,
                type: WorkloadTypesEnum[source.type.toUpperCase()],
                dedupe: source.dedupe,
                size: source.size,
                annualGrowth: source.annualGrowth,
                dailyGrowth: source.dailyGrowth,
                dailyChange: source.dailyChange,
                deploymentPeriod: source.deploymentPeriod,
                ltr,
                ...source.retention,
                comments: source.additionalDetails.comments,
            };
        }),
    };

    const existingSourcesTableData = {
        dataPrimaryHeaders: existingSourcesHeaders.primary,
        dataSources: deploymentSummary?.details?.length > 0 ? deploymentSummary.details : [],
    };

    return {
        dateText,
        formattedTiles,
        backupTableData,
        sourcesTableData,
        existingSourcesTableData,
        sourcesColumnWidths,
    };
};

export const parseAssumptionsPayloadToNumericValues = (payload: AssumptionsValues): AssumptionsValues => ({
    dedupe: {
        ...payload.dedupe,
        nas: Number(payload.dedupe.nas),
        windows: Number(payload.dedupe.windows),
        linux: Number(payload.dedupe.linux),
        msSql: Number(payload.dedupe.msSql),
        oracle: Number(payload.dedupe.oracle),
        vMware: Number(payload.dedupe.vMware),
        hyperV: Number(payload.dedupe.hyperV),
        nutanixAhv: Number(payload.dedupe.nutanixAhv),
        archiveFsNas: Number(payload.dedupe.archiveFsNas),
        ec2: Number(payload.dedupe.ec2),
        sapHana: Number(payload.dedupe.sapHana),
        azureVm: Number(payload.dedupe.azureVm),
        rds: Number(payload.dedupe.rds),
        azureSql: Number(payload.dedupe.azureSql),
    },
    ltr: {
        ...payload.ltr,
        ltr: Number(payload.ltr.ltr),
    },
    dtc: {
        ...payload.dtc,
        dtc: Number(payload.dtc.dtc),
    },
    pricing: {
        ...payload.pricing,
        business: Number(payload.pricing.business),
        enterprise: Number(payload.pricing.enterprise),
        elite: Number(payload.pricing.elite),
        govCloudAddon: Number(payload.pricing.govCloudAddon),
    },
    changeRates: {
        ...payload.changeRates,
        weeklyMultiplier: Number(payload.changeRates.weeklyMultiplier),
        weeklyCap: Number(payload.changeRates.weeklyCap),
        monthlyMultiplier: Number(payload.changeRates.monthlyMultiplier),
        monthlyCap: Number(payload.changeRates.monthlyCap),
        yearlyMultiplier: Number(payload.changeRates.yearlyMultiplier),
        yearlyCap: Number(payload.changeRates.yearlyCap),
    },
});
