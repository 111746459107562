import React from 'react';
import { Document, Page, View, Image, Text } from '@react-pdf/renderer';
import { workloadTypeAliases } from 'utils/utils';
import { DocumentDataType } from 'types';
import { PRICING_INFO_TITLES, getBackupTableTitles } from 'views/constants';
import styles, { stylesMapping, getStyledComponent } from './styles';
import { customStyleForBodyCol, existingSourcesColumnWidths } from './utils';

const CellTextStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <Text style={getStyledComponent('cellText', style)} {...restProps}>
            {children}
        </Text>
    );
};

const TextStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <Text style={getStyledComponent('text', style)} {...restProps}>
            {children}
        </Text>
    );
};

const ViewStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <View style={getStyledComponent('rowView', style)} {...restProps}>
            {children}
        </View>
    );
};

const HeaderStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <Text style={getStyledComponent('headerText', style)} {...restProps}>
            {children}
        </Text>
    );
};

const mapToCell = (data, index, keyPrefix, style) => (
    <CellTextStyled style={Array.isArray(style) ? style[0] : style} key={`${keyPrefix}_${index}`}>
        {data}
    </CellTextStyled>
);

type Props = {
    summaryGraphSrc: string;
    priceInformation: boolean;
    isDell: boolean;
    customerDetails: { customername?: String; organization?: String; customerType: string; balanceCredits: string };
    showCustomerInfo: Boolean;
};

const DocumentLayout = ({
    summaryGraphSrc,
    priceInformation,
    isDell = false,
    customerDetails,
    showCustomerInfo,
    dateText,
    formattedTiles,
    backupTableData,
    sourcesTableData,
    sourcesColumnWidths,
    existingSourcesTableData,
}: Props & DocumentDataType): JSX.Element => {
    const title = isDell ? 'Dell APEX Backup Service Credit Sizing Tool' : 'Druva Credit Sizing Tool';

    const isCustomerDetailsRowAvailable = customerDetails.customername !== '' || customerDetails.organization !== '';

    const BACKUP_TABLE_TITLES = getBackupTableTitles(isDell, +customerDetails.balanceCredits < 0);

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.heading}>
                    <TextStyled>{title}</TextStyled>
                    <TextStyled style={stylesMapping.timestamp}>{dateText}</TextStyled>
                </View>
                <TextStyled style={stylesMapping.title}>Result</TextStyled>
                {showCustomerInfo && isCustomerDetailsRowAvailable && (
                    <View>
                        <TextStyled style={{ ...stylesMapping.headerText, marginTop: 10 }}>Customer Details</TextStyled>
                        <ViewStyled style={stylesMapping.customerDetails}>
                            {customerDetails.customername !== '' && (
                                <View>
                                    <ViewStyled>
                                        <TextStyled style={{ fontSize: 12 }}>Customer Name: </TextStyled>
                                        <TextStyled style={{ fontSize: 12 }}>{customerDetails.customername}</TextStyled>
                                    </ViewStyled>
                                </View>
                            )}
                            {customerDetails.organization !== '' && (
                                <View>
                                    <ViewStyled>
                                        <TextStyled style={{ fontSize: 12 }}>Organization/Site: </TextStyled>
                                        <TextStyled style={{ fontSize: 12 }}>{customerDetails.organization}</TextStyled>
                                    </ViewStyled>
                                </View>
                            )}
                        </ViewStyled>
                    </View>
                )}
                <HeaderStyled style={{ marginTop: 20 }}>Summary</HeaderStyled>
                <View style={isDell ? styles.summaryDell : styles.summary}>
                    {formattedTiles.map((tile, index) => (
                        <View
                            key={tile.label}
                            style={getStyledComponent('tileView', {
                                width: priceInformation ? tile.width ?? '11.5vw' : '22vw',
                                marginRight: isDell && index !== formattedTiles.length - 1 ? 5 : 0,
                            })}
                        >
                            <TextStyled>{tile.label}</TextStyled>
                            <TextStyled>{tile.value}</TextStyled>
                        </View>
                    ))}
                </View>
                <HeaderStyled style={{ marginTop: 20 }}>Backup</HeaderStyled>
                <View style={styles.backupCol}>
                    <View
                        style={getStyledComponent('tableView', {
                            width: '100%',
                            marginBottom: 20,
                        })}
                    >
                        <ViewStyled>
                            <CellTextStyled style={{ width: '42%' }} />
                            {backupTableData.dataYears.data.map((year, index) =>
                                mapToCell(year, index, 'year', backupTableData.dataYears.style),
                            )}
                        </ViewStyled>
                        <ViewStyled style={{ backgroundColor: '#f7f7f8' }}>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#f9e4c6' }}>
                                {BACKUP_TABLE_TITLES.dataSourceChanges}
                            </CellTextStyled>
                            {backupTableData.dataSourceChanges.data.map((sourceChange, index) =>
                                mapToCell(
                                    sourceChange,
                                    index,
                                    'sourceChanges',
                                    backupTableData.dataSourceChanges.style,
                                ),
                            )}
                        </ViewStyled>
                        <ViewStyled>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#ffebcc' }}>
                                {BACKUP_TABLE_TITLES.dataTargetData}
                            </CellTextStyled>
                            {backupTableData.dataTargetData.data.map((targetData, index) =>
                                mapToCell(targetData, index, 'targetData', backupTableData.dataTargetData.style),
                            )}
                        </ViewStyled>
                        <ViewStyled style={{ backgroundColor: '#f7f7f8' }}>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#f9e4c6' }}>
                                {BACKUP_TABLE_TITLES.dataCreditsStandard}
                            </CellTextStyled>
                            {backupTableData.dataCreditsStandard.data.map((creditsStandard, index) =>
                                mapToCell(
                                    creditsStandard,
                                    index,
                                    'creditsNoLtrs',
                                    backupTableData.dataCreditsStandard.style,
                                ),
                            )}
                        </ViewStyled>
                        <ViewStyled>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#ffebcc' }}>
                                {BACKUP_TABLE_TITLES.dataCreditsLtr}
                            </CellTextStyled>
                            {backupTableData.dataCreditsLtr.data.map((creditsLtr, index) =>
                                mapToCell(creditsLtr, index, 'creditsLtrs', backupTableData.dataCreditsLtr.style),
                            )}
                        </ViewStyled>
                        <ViewStyled style={{ backgroundColor: '#f7f7f8' }}>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#f9e4c6' }}>
                                {BACKUP_TABLE_TITLES.dataCreditsDtc}
                            </CellTextStyled>
                            {backupTableData.dataCreditsDtc.data.map((creditsDtc, index) =>
                                mapToCell(creditsDtc, index, 'dtc', backupTableData.dataCreditsDtc.style),
                            )}
                        </ViewStyled>
                        {customerDetails?.customerType === 'Existing' && (
                            <ViewStyled>
                                <CellTextStyled style={{ width: '42%', backgroundColor: '#ffebcc' }}>
                                    {BACKUP_TABLE_TITLES.dataCreditsBalance}
                                </CellTextStyled>
                                {backupTableData.dataCreditsBalance.data.map((creditsBalance, index) =>
                                    mapToCell(
                                        creditsBalance,
                                        index,
                                        'creditsBalance',
                                        backupTableData.dataCreditsBalance.style,
                                    ),
                                )}
                            </ViewStyled>
                        )}

                        <ViewStyled style={{ backgroundColor: '#ffebcc' }}>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#ffdba3' }}>
                                {BACKUP_TABLE_TITLES.dataCreditsTotal}
                            </CellTextStyled>
                            {backupTableData.dataCreditsTotal.data.map((creditsTotal, index) =>
                                mapToCell(creditsTotal, index, 'creditsTotal', backupTableData.dataCreditsTotal.style),
                            )}
                        </ViewStyled>
                        <View style={styles.dividerView} />
                        {backupTableData.dataDiscount && !isDell && (
                            <ViewStyled style={{ backgroundColor: '#e9ebec' }}>
                                <CellTextStyled style={{ width: '42%' }}>
                                    {PRICING_INFO_TITLES.dataDiscount}
                                </CellTextStyled>
                                {backupTableData.dataDiscount.data.map((discount, index) =>
                                    mapToCell(`${discount}%`, index, 'discount', backupTableData.dataDiscount.style),
                                )}
                            </ViewStyled>
                        )}
                        {backupTableData.dataBackupACV && !isDell && (
                            <ViewStyled>
                                <CellTextStyled style={{ width: '42%' }}>
                                    {PRICING_INFO_TITLES.dataBackupACV}
                                </CellTextStyled>
                                {backupTableData.dataBackupACV.data.map((backup, index) =>
                                    mapToCell(`$${backup}`, index, 'backupACV', backupTableData.dataBackupACV.style),
                                )}
                            </ViewStyled>
                        )}
                        {backupTableData.dataEffectivePrice && !isDell && (
                            <ViewStyled style={{ backgroundColor: '#e9ebec' }}>
                                <CellTextStyled style={{ width: '42%' }}>
                                    {PRICING_INFO_TITLES.dataEffectivePrice}
                                </CellTextStyled>
                                {backupTableData.dataEffectivePrice.data.map((price, index) =>
                                    mapToCell(
                                        `$${price}`,
                                        index,
                                        'effectivePrice',
                                        backupTableData.dataEffectivePrice.style,
                                    ),
                                )}
                            </ViewStyled>
                        )}
                    </View>
                    <Image style={[styles.summaryGraphCol]} src={summaryGraphSrc} />
                </View>
                <View style={styles.footer} fixed>
                    <TextStyled style={{ fontSize: 7 }}>
                        This tool is confidential and proprietary to {isDell ? 'Dell Technology' : 'Druva'} and should
                        not be copied, distributed or reproduced in whole or in part, nor passed to any third party.
                    </TextStyled>
                    <TextStyled
                        style={{ position: 'absolute', right: 20 }}
                        fixed
                        render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
                    />
                </View>
            </Page>

            <Page size="A4" style={styles.body}>
                <HeaderStyled style={{ marginTop: 40 }}>Sources</HeaderStyled>
                {existingSourcesTableData.dataSources.length > 0 && (
                    <>
                        <HeaderStyled style={{ marginTop: 10, color: '#343A40', fontSize: '10px' }}>
                            Existing Data Sources
                        </HeaderStyled>
                        <View style={styles.existingSources}>
                            <View style={getStyledComponent('tableView', {})}>
                                <ViewStyled style={{ backgroundColor: '#FFCC80' }}>
                                    {existingSourcesTableData.dataPrimaryHeaders.map((header, index) =>
                                        mapToCell(header.header, index, 'primaryHeader', header.style),
                                    )}
                                </ViewStyled>
                                {existingSourcesTableData.dataSources.map((source, index) => {
                                    const { id, ...values } = source;
                                    return (
                                        <ViewStyled
                                            style={{
                                                backgroundColor: index % 2 === 0 ? '#fff' : '#f7f7f8',
                                            }}
                                            key={id}
                                        >
                                            {existingSourcesTableData.dataPrimaryHeaders.map((obj, keyIndex) =>
                                                mapToCell(values[obj.accessor], keyIndex, id, {
                                                    width: existingSourcesColumnWidths[keyIndex],
                                                }),
                                            )}
                                        </ViewStyled>
                                    );
                                })}
                            </View>
                        </View>
                    </>
                )}
                {sourcesTableData?.dataSources?.length > 0 && (
                    <>
                        <HeaderStyled style={{ marginTop: 10, color: '#343A40', fontSize: '10px' }}>
                            New Data Sources
                        </HeaderStyled>
                        <View style={styles.sources}>
                            <View style={getStyledComponent('tableView', {})}>
                                <ViewStyled style={{ backgroundColor: '#FFCC80' }}>
                                    {sourcesTableData.dataPrimaryHeaders.map((header, index) =>
                                        mapToCell(header.header, index, 'primaryHeader', header.style),
                                    )}
                                </ViewStyled>
                                <ViewStyled style={{ backgroundColor: '#FFEBCC' }}>
                                    {sourcesTableData.dataSecondaryHeaders.map((header, index) =>
                                        mapToCell(header.header, index, 'secondaryHeader', header.style),
                                    )}
                                </ViewStyled>
                                {sourcesTableData.dataSources.map((source, index) => {
                                    const { id, ...values } = source;
                                    delete values.dtc;
                                    const valuesToRender = {
                                        ...values,
                                        ...(values.type === workloadTypeAliases.sapHana
                                            ? {
                                                  weekly: 'N/A',
                                                  monthly: 'N/A',
                                                  yearly: 'N/A',
                                              }
                                            : {}),
                                    };

                                    return (
                                        <ViewStyled
                                            style={{
                                                backgroundColor: index % 2 === 0 ? '#fff' : '#f7f7f8',
                                            }}
                                            key={id}
                                        >
                                            {Object.keys(valuesToRender).map((key, keyIndex) =>
                                                mapToCell(valuesToRender[key], keyIndex, id, {
                                                    width: sourcesColumnWidths[keyIndex],
                                                    ...(customStyleForBodyCol[key] ?? {}),
                                                }),
                                            )}
                                        </ViewStyled>
                                    );
                                })}
                            </View>
                        </View>
                    </>
                )}
                <View style={styles.footer} fixed>
                    <TextStyled style={{ fontSize: 7 }}>
                        This tool is confidential and proprietary to {isDell ? 'Dell Technology' : 'Druva'} and should
                        not be copied, distributed or reproduced in whole or in part, nor passed to any third party.
                    </TextStyled>
                    <TextStyled
                        style={{ position: 'absolute', right: 20 }}
                        fixed
                        render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
                    />
                </View>
            </Page>
        </Document>
    );
};
export default DocumentLayout;
