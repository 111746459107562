import {
    EXISTING_SOURCE_TABLE_PRIMARY_HEADERS,
    SOURCE_TABLE_PRIMARY_HEADERS,
    SOURCE_TABLE_SECONDARY_HEADERS,
} from '../../../constants';

export const columns = [
    {
        Header: SOURCE_TABLE_PRIMARY_HEADERS.sources,
        columns: [
            {
                Header: ' ',
                accessor: 'index',
            },
        ],
    },
    {
        Header: SOURCE_TABLE_PRIMARY_HEADERS.serverDetails,
        columns: [
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.workload,
                accessor: 'type',
            },
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.dedupe,
                accessor: 'dedupe',
            },
            {
                Header: 'Data size (TB)',
                accessor: 'size',
            },
        ],
    },
    {
        Header: SOURCE_TABLE_PRIMARY_HEADERS.dataChange,
        columns: [
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.annualGrowth,
                accessor: 'annualGrowth',
            },
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.dailyGrowth,
                accessor: 'dailyGrowth',
            },
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.dailyChange,
                accessor: 'dailyChange',
            },
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.deploymentPeriod,
                accessor: 'deploymentPeriod',
            },
        ],
    },
    {
        Header: SOURCE_TABLE_PRIMARY_HEADERS.storageTier,
        columns: [
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.warmLtr,
                accessor: 'ltr',
            },
        ],
    },
    {
        Header: SOURCE_TABLE_PRIMARY_HEADERS.retention,
        columns: [
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.dailies,
                accessor: 'retention.daily',
            },
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.weeklies,
                accessor: 'retention.weekly',
            },
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.monthlies,
                accessor: 'retention.monthly',
            },
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.yearlies,
                accessor: 'retention.yearly',
            },
        ],
    },
    {
        Header: SOURCE_TABLE_PRIMARY_HEADERS.additionalDetails,
        columns: [
            {
                Header: SOURCE_TABLE_SECONDARY_HEADERS.comments,
                accessor: 'additionalDetails.comments',
                showTooltip: true,
                tooltipPlacement: 'bottom',
                charLength: 70, // after this length show tooltip
                style: {
                    textAlign: 'left',
                    minWidth: '150px',
                },
            },
        ],
    },
];

export const existingDataSourceColumns = [
    {
        Header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.serviceName,
        accessor: 'serviceName',
    },
    {
        Header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.configuredBackupsets,
        accessor: 'configuredBackupsets',
    },
    {
        Header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.dataSize,
        accessor: 'dataSize',
    },
    {
        Header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.annualGrowthRate,
        accessor: 'annualGrowthRate',
    },
    {
        Header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.dailyChangeRate,
        accessor: 'dailyChangeRate',
    },
    {
        Header: EXISTING_SOURCE_TABLE_PRIMARY_HEADERS.effectiveDedupRatio,
        accessor: 'effectiveDedupRatio',
    },
];
