import { StyleSheet } from '@react-pdf/renderer';

export const stylesMapping = {
    logo: {
        height: 16,
        marginRight: 14,
    },

    timestamp: {
        position: 'absolute',
        right: 0,
    },

    title: {
        width: '100%',
        textAlign: 'center',
        paddingTop: 20,
        color: '#146EB4',
        fontStyle: 'bold',
        fontSize: 16,
    },
    customerDetails: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '60%',
        fontSize: 24,
    },
    tableView: {
        width: 'auto',
    },
    rowView: {
        flexDirection: 'row',
    },
    cellText: {
        color: '#232f3e',
        fontSize: '8px',
        margin: 'auto',
        textAlign: 'center',
        paddingLeft: '1px',
        paddingRight: '1px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    text: {
        color: '#232f3e',
        fontSize: '8px',
    },
    headerText: {
        color: '#146eb4',
        fontSize: '13px',
    },
    tileView: {
        fontSize: '5px',
        minHeight: '25px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#c4c4c4',
        borderRadius: '2px',
        padding: '5px 4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: '5px',
    },
};

const styles = StyleSheet.create({
    body: {
        paddingVertical: 20,
        paddingHorizontal: 30,
    },

    heading: {
        width: '100%',
    },

    dividerView: {
        height: '15px',
    },

    summaryGraph: {
        objectFit: 'contain',
        width: '44%',
    },

    summaryGraphCol: {
        objectFit: 'contain',
        width: '60%',
        paddingTop: '10px',
        alignSelf: 'center',
    },

    sources: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
    },

    existingSources: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
    },

    footer: {
        color: '#232F3E',
        marginTop: 'auto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
    },

    summaryDell: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginRight: 5,
    },

    summary: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },

    backupCol: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 10,
    },

    backup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
});

export const getStyledComponent = (key, style) => StyleSheet.create({ ...stylesMapping[key], ...style });

export default styles;
