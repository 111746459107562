import { SkuDefaultValuesType } from 'store/slices/sku/sku.types';
import { SourceType } from './store/slices/sources/sources.types';

export type Retention = {
    daily: number;
    weekly: number;
    monthly: number;
    yearly: number;
};

export enum WorkloadTypesEnum {
    SELECT = 'Select',
    NAS = 'NAS',
    VMWARE = 'VMware',
    WINDOWS = 'Windows',
    LINUX = 'Linux',
    MSSQL = 'MS-SQL',
    ORACLE = 'Oracle',
    HYPERV = 'Hyper-V',
    ARCHIVEFSNAS = 'Archive (FS/NAS)',
    NUTANIXAHV = 'Nutanix AHV',
    EC2 = 'EC2',
    SAPHANA = 'SAP HANA',
    AZUREVM = 'Azure VM',
    RDS = 'RDS',
    AZURESQL = 'Azure SQL',
}

export enum WorkloadEnum {
    SELECT = 'select',
    NAS = 'nas',
    VMWARE = 'vMware',
    WINDOWS = 'windows',
    LINUX = 'linux',
    MSSQL = 'msSql',
    ORACLE = 'oracle',
    HYPERV = 'hyperV',
    ARCHIVEFSNAS = 'archiveFsNas',
    NUTANIXAHV = 'nutanixAhv',
    EC2 = 'ec2',
    SAPHANA = 'sapHana',
    AZUREVM = 'azureVm',
    RDS = 'rds',
    AZURESQL = 'azureSql',
}

export type ApiInputs = {
    sources: Array<SourceType>;
    duration: number;
} & SkuDefaultValuesType;

export type AllSourcesDataTypes = string;

export type CalculatedDataObject = {
    dailyData: Array<dailyMonthlyData>;
    monthlyData: Array<dailyMonthlyData>;
};

export type dailyMonthlyData = {
    total?: number;
    target?: number;
    creditsMonthly?: number;
    cumulatedCredits?: number;
    totalDaily?: number;
    targetTbDaily?: number;
};

export type CalculatedDataTypes = 'ltr' | 'noLtr' | 'dtc' | 'allSources';

export type CalculatedData = { [T in CalculatedDataTypes]?: CalculatedDataObject };

export type CalculatedDataResponse = {
    results: CalculatedData;
};

export type envVariableState = {
    USER_POOL_CLIENT_ID: string;
    CLOUD_NAME_DIRECTORY_SVC_BASE_URL_MAP: any;
    COGNITO_DOMAIN: string;
    MAINLINE_DEP0_API_GATEWAY_URL: string;
    OKTA_IDENTITY_PROVIDER_NAME: string;
    PING_IDENTITY_PROVIDER_NAME: string;
    SIGN_IN_URL: string;
    USER_POOL_ID: string;
};

export type DirectorySvcResponseState = {
    serviceName: string;
    protocol: string;
    endpoint: string;
    port: number;
    deploymentID: number;
};

export type DeploymentConfigResponse = {
    envData: envVariableState;
    globalApiUrlCloudNameMap: {};
    directorySvcResponse: DirectorySvcResponseState;
};

export type globalAPIUrlCloudNameMapState = {
    mainline: string;
    gov: string;
    fedramp: string;
};

export type creditDetails = {
    creditPools: creditPoolState[];
};

export type creditPoolState = {
    currentCreditBalance: string;
    lastUpdatedDay: number;
};

export type SizingStatusResponse = {
    sizingStatus: string;
};

export type AllSourcesDataResponse = {
    results: CalculatedData;
};

export type workloads = WorkloadTypesEnum;
export type IteratorType = number[] | string[];

export type CompareGraphKeys = 'month' | 'Monthly Credits LTR' | 'Monthly Credits Standard' | 'Total Credit Cumul';
export type CompreGraphHookType = { [T in CompareGraphKeys]: number };
export type TargetGraphKeys =
    | 'month'
    | 'EWL Storage LTR'
    | 'EWL Storage Standard (TB)'
    | 'Storage LTR'
    | 'Storage Standard (TB)';
export type TargetGraphHookType = Partial<{ [T in TargetGraphKeys]: number }>;
export type SummaryGraphKeys = 'year' | 'Average Source+Changes (TB)' | 'EWL Storage (TB)' | 'Storage (TB)';
export type SummaryGraphHookType = Partial<{ [T in SummaryGraphKeys]: number }>;

export type GraphsTransformArgs<T> = (
    el: dailyMonthlyData,
    index: number,
    results: CalculatedData,
    isDell?: boolean,
    firstYearMonths?: number,
) => T;
export type SummaryGraphsTransformArgs<T> = (
    el: number,
    index: number,
    results: CalculatedData,
    isDell?: boolean,
    firstYearMonths?: number,
) => T;

export type TilesType = {
    label: string;
    value: number | string;
    isEditable: boolean;
    width?: string;
};

export type BackupData = {
    sourceChanges: number[];
    targetData: number[];
    creditsLtr: number[];
    creditsNoLtr: number[];
    dtc: number[];
    creditsTotal: number[];
};

export type SheetData = {
    sheetName: string;
    data: Record<string, any>[];
};

export type DynamicObjectType = {
    [key: string]: any;
};

type BackupTableData = {
    dataYears;
    dataSourceChanges;
    dataTargetData;
    dataCreditsLtr;
    dataCreditsStandard;
    dataCreditsBalance;
    dataCreditsTotal;
    dataCreditsDtc;
    dataDiscount;
    dataBackupACV;
    dataEffectivePrice;
};
type SourcesTableData = {
    dataPrimaryHeaders: any[];
    dataSecondaryHeaders: any[];
    dataSources: any[];
};
type ExistingSourcesTableData = {
    dataPrimaryHeaders: any[];
    dataSources: any[];
};

export type DocumentDataType = {
    dateText: string;
    formattedTiles: Array<TilesType>;
    backupTableData: BackupTableData;
    sourcesTableData: SourcesTableData;
    sourcesColumnWidths: Array<string>;
    existingSourcesTableData: ExistingSourcesTableData;
};
