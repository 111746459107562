import { withStyles, TextField } from '@material-ui/core';

export const TextFieldBaseStyled = withStyles({
    root: {
        background: '#fff',
        borderRadius: 3,
        color: '#232F3E',
        height: 'inherit',
        margin: 'auto 0',
        border: '1px solid rgba(35, 47, 62, 0.3)',
        fontFamily: 'Lato',
        padding: '2px 5px',
        '& .MuiInput-underline:focus': {
            border: '0 !important',
        },
        '& .MuiInput-underline:before': {
            border: '0 !important',
        },
        '& .MuiInput-underline:after': {
            border: '0 !important',
        },
        '&:hover': {
            border: '1px solid #ff9900',
        },
        '&:has(.Mui-disabled):hover': {
            border: '1px solid rgba(35, 47, 62, 0.3)',
        },
        '& textarea': {
            textAlign: 'left',
        },
    },
})(TextField);
